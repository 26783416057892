import React from "react";
import { Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const HomeBanner = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    autoplay: true,
  };
  return (
    <>
      <section className="margin home-banner-lg">
        <div className="slider-container">
          <Slider {...settings}>
            <div className="homebanner-bg banner-content d-flex justify-content-start align-items-center banner-con-height">
              <Container><div className="con-ban">

                <h1>
                  Experience Our Journey: Efficiency and Reliability in
                  Distribution
                </h1>
              </div>
              </Container>
            </div>
            <div className="homebanner-bg2 banner-content d-flex justify-content-start align-items-center banner-con-height">
              <Container><div className="con-ban2">

                <h1>
                Exclusive <span>brands for 
                Exclusive community</span>
                </h1>
              </div>
              </Container>
            </div>
          </Slider>
        </div>
      </section>
      <section className="homebannerS-bg margin">
        <Container>
          <div className="banner-content d-flex justify-content-start align-items-center sm-banner-text">
            <h1>
              Experience Our Journey: Efficiency and Reliability in Distribution
            </h1>
          </div>
        </Container>
      </section>
    </>
  );
};

export default HomeBanner;
