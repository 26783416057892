import React from "react";
import { Container } from "react-bootstrap";

const Product = () => {
  return (
    <>
      <section className="py-4 margin">
        <Container>
          <div className="text-center hiring py-2">
            <h1>Our Products</h1>
            <p>Home - Our Products</p>
          </div>
        </Container>
        <div className="lg-ban">
          <img
            src="/assets/V Cosumer.png"
            className="img-fluid cosumer"
            alt="dsa"
          />
        </div>
        <div className="sm-ban">
            <img className="img-fluid" src="/assets/sm1.png"/>
        </div>
      </section>
      <section className="py-4">
        <div>
          <h2 className="title text-center pb-3">
            Brands For Our Exclusive Communities{" "}
          </h2>
          <img className="img-fluid lg-ban" src="/assets/V Samsung.png" />
          <img className="img-fluid sm-ban" src="/assets/sm2.png"/>
        </div>
      </section>
      <section className="py-4 sm-view">
        <div className="row mx-0">
          <div className="col-lg-6 col-md-6 col-sm-12 ps-0" id="sm-product">
            <div>
              <img className="img-fluid" src="/assets/V Vivo.png" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 pe-0" id="sm-product2">
            <div>
              <img className="img-fluid" src="/assets/V oppo.png" />
            </div>
          </div>
        </div>
      </section>
      <section className="py-4 lg-view">
        <div className="row mx-0">
          <div className="col-lg-6 col-md-6 col-sm-12 ps-0" id="sm-product">
            <div>
              <img className="img-fluid" src="/assets/jodhchqwj.png" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 pe-0" id="sm-product2">
            <div>
              <img className="img-fluid" src="/assets/V oppo.png" />
            </div>
          </div>
        </div>
      </section>
      <section className="py-4">
        <div>
          <img className="img-fluid lg-ban" src="/assets/v iphone.png" />
          <img className="img-fluid sm-ban" src="/assets/sm3.png"/>
        </div>
      </section>
      <section className="py-4">
        <div className="row mx-0 pb-4">
          <div className="col-lg-6 col-md-6 col-sm-12 ps-0" id="sm-product">
            <div>
              <img className="img-fluid sm-view" src="/assets/V Hp - Copy.png" />
              <img className="img-fluid lg-view" src="/assets/uidhcudh.png" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 pe-0" id="sm-product2">
            <div>
              <img className="img-fluid" src="/assets/V Dell (1).png" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;
