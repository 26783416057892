import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const [scrolled, setScrolled] = useState(true);
  const [isScrolled, setIsScrolled] = useState(true);

  const [isHover, setIsHover] = useState({
    home: false,
    services: false,
    project: false,
    pages: false,
    shop: false,
    blog: false,
    multiplepage: false,
    onepage: false,
    team: false,
  });
  const handleMouseEnter = (item: any) => {
    setIsHover((prevState) => ({
      ...prevState,
      [item]: true,
    }));
  };

  const handleMouseLeave = (item: any) => {
    setIsHover((prevState) => ({
      ...prevState,
      [item]: false,
    }));
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate=useNavigate()

  const handlenavContact=()=>{
    navigate("/")
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`lg-hide m-header ${
          isScrolled ? "header scrolled" : "header"
        }`}
      >
        <Container>
          <Navbar.Brand href="/">
            <img className="img-fluid header-logo" src="/assets/V next Logo-01.png" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="servicebd-color">
              <Link to={"/"} className="head-nav"> Home</Link>
              {/* <NavDropdown
                className="head-nav"
                title="Products"
                id="collapsible-nav-dropdown"
              >
                <NavDropdown.Item href="">
                Tabs & Laptops
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Cameras 
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Music Systems
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                MP3 Players
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                DVD Players  
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Home Appliances 
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Washing Machines
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Refrigerators
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Cooking Systems
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Air Conditioners 
                </NavDropdown.Item>
              </NavDropdown> */}
              <Link to={"/our-products"} className="head-nav">
                Products
                </Link>
              <Link to={"/hiring"} className="head-nav">Hiring</Link>
              <Link to={"/about-us"} className="head-nav">About</Link>
              <Nav.Link href="#contactus" onClick={handlenavContact} className="head-nav py-0">
                Contact us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <section>
        <Container>
          <div className="row">
            <header
              className={`main-header header-two ${
                scrolled ? "fixed-header" : ""
              }`}
            >
              {/*Header-Upper*/}
              <div className="header-upper">
                <div className="container clearfix">
                  <div className="header-inner rel d-flex align-items-center justify-content-between">
                    <div className="nav-outer clearfix mx-0">
                      <nav className="main-menu d-none d-lg-block navbar-expand-lg">
                        <div className="navbar-collapse collapse clearfix">
                          <ul className="navigation clearfix mb-0 px-0">
                            <li
                              className="dropdown"
                              onMouseEnter={() => handleMouseEnter("home")}
                              onMouseLeave={() => handleMouseLeave("home")}
                            >
                              <Link className="home" to="/">
                                Home
                              </Link>
                            </li>
                            {/* <li
                              className="dropdown service-H"
                              onMouseEnter={() => handleMouseEnter("services")}
                              onMouseLeave={() => handleMouseLeave("services")}
                            >
                              <a href="#">Products</a> <span style={{paddingTop:"6px"}}><i className="fi fi-ss-angle-small-down"></i></span>
                              <div className="polygon-Header">
                                <img
                                  src="/assets/Polygon 3.png"
                                  className="img-fluid"
                                />
                              </div>
                              <ul
                                className="header-lg-dropdown head-li p-3"
                                style={{
                                  display: `${
                                    isHover.services ? "block" : "none"
                                  }`,
                                }}
                              >
                                <h3 className="drop-title">Our Products</h3>
                                <div className="d-flex list-li">
                                  <div>
                                    <li>
                                      <a href=""><i className="fi fi-ss-laptop me-1"></i>Tabs & Laptops</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-camera-retro me-1"></i>Cameras</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-mp3-player me-1"></i>Music Systems</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-list-music me-1"></i>MP3 Players</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-album me-1"></i>DVD Players</a>
                                    </li>
                                  </div>
                                  <div>
                                  <li>
                                      <a href=""><i className="fi fi-ss-marketplace-store me-1"></i>Home Appliances </a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-washer me-1"></i>Washing Machines</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-refrigerator me-1"></i>Refrigerators</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-grill-hot-alt me-1"></i>Cooking Systems</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-air-conditioner me-1"></i>Air Conditioners </a>
                                    </li>
                                  </div>
                                </div>
                              </ul>
                              <div className="dropdown-btn">
                                <span className="fas fa-chevron-down" />
                              </div>
                            </li> */}
                            <li className="dropdown">
                              <Link to="/our-products">Products </Link>
                            </li>
                            <li className="dropdown">
                              <Link to="/hiring">Hiring</Link>
                            </li>
                            <li className="dropdown">
                              <Link to="/about-us">About</Link>
                            </li>
                          </ul>
                        </div>
                      </nav>
                    </div>
                    <div className="logo-outer">
                      <div className="logo">
                        <Link to="/">
                          <img
                            className="img-fluid header-logo p-1"
                            src="/assets/V next Logo-01.png"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="contact-show">
                      <a href="#contactus" onClick={handlenavContact} className="contact-btn" >Contact Us</a>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Header;
