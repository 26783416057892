import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeM from "../MainFiles/HomeM";
import HiringM from "../MainFiles/HiringM";
import AboutM from "../MainFiles/AboutM";
import Products from "../MainFiles/Products";

const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeM />}></Route>
          <Route path="/hiring" element={<HiringM />}></Route>
          <Route path="/about-us" element={<AboutM />}></Route>
          <Route path="/our-products" element={<Products />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
