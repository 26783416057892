import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer/Footer";
import Hiring from "../Home/Hiring";

const HiringM = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  return (
    <>
      <Header />
      <Hiring />
      <Footer />
    </>
  );
};

export default HiringM;
