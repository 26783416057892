import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { IoMdCloudUpload } from "react-icons/io";
import axios from "axios";
import { Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const { Dragger } = Upload;

const Hiring = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);

  const handleInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (info: any) => {
    if (info.fileList && info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      console.log(file, "file");

      setFile(file);
    } else {
      setFile(null);
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!formData.name || !formData.phone || !formData.email || !file) {
      setOpen(true);
      return;
    }

    const data = new FormData();
    data.append("name", formData.name);
    data.append("phone", formData.phone);
    data.append("email", formData.email);
    data.append("file", file);

    try {
      await axios.post("https://vnextbackend.onrender.com/send-email", data);
      setFormData({ name: "", phone: "", email: "" });
      setFile(null)
    } catch (error) {
      alert("Error submitting form: " + error);
    }
  };
  return (
    <>
      <section className="py-4 margin">
        <Container>
          <div className="text-center hiring py-2">
            <h1>Hiring</h1>
            <p>Home - Hiring</p>
          </div>
        </Container>
      </section>
      <section className="hire-bg">
        <Container>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center hire-col">
              <div>
                <h2>Be a part of our Mission</h2>
                <p>
                  We’re looking for passionate people to join us on our mission.
                  We value flat hierarchies, clear communication, and full
                  ownership and responsibility.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="pt-3">
        <Container>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div>
                <h3>Fill The Form:</h3>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label className="label">Name *</label>
                    <br />
                    <input
                      className="form-name p-2"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    <br />
                    <div className="d-flex justify-content-between align-items-center py-3">
                      <div style={{ width: "49%" }}>
                        <label className="label">Phone No *</label>
                        <br />
                        <input
                          className="form-phone p-2"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                        />
                        <br />
                      </div>
                      <div style={{ width: "49%" }}>
                        <label className="label">Email id *</label>
                        <br />
                        <input
                          className="form-phone p-2"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                        <br />
                      </div>
                    </div>
                    <label className="label">Upload Your Resume *</label>
                    <Dragger
                      beforeUpload={() => false}
                      onChange={handleFileChange}
                      multiple={false}
                    >
                      <p className="ant-upload-drag-icon mb-0">
                        <IoMdCloudUpload style={{ fontSize: "2rem" }} />
                      </p>
                      <p className="ant-upload-text">Upload Your File</p>
                    </Dragger>
                    <div className="text-center pt-4 pb-4">
                      <button className="form-btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message="All fields required"
                  action={action}
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Hiring;
