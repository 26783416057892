import React, { useEffect } from 'react'
import Header from "../Header";
import Footer from "../Footer/Footer";
import Aboutus from '../Home/Aboutus';
const AboutM = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
    return (
        <>
            <Header/>
            <Aboutus/>
            <Footer/>
        </>
    )
}

export default AboutM
