import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";

const OurProducts = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [data, setData] = useState({
    name: "",
    mail: "",
    phonenumber: "",
    message: "",
  });

  const handleContact = async (e: any) => {
    e.preventDefault();
    try {
      await axios.post("https://vnextbackend.onrender.com/ContactUs", {
        name: data.name,
        userEmail: data.mail,
        userPhonenumber: data.phonenumber,
        message: data.message,
      });
      setData({
        name: "",
        mail: "",
        phonenumber: "",
        message: "",
      });
      alert("Mail Send Successfuly");
    } catch (err: any) {
      console.log(err.message);
      alert("Got internal error");
    }
  };
  return (
    <>
      <section className="py-4">
        <Container>
          <div>
            <h2 className="text-center py-3 title">Our Products</h2>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 col-md-12 col-sm-12">
              <div className="row sm-product">
                <div className="col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center">
                  <div>
                    <div className="phone-circle">
                      <img
                        className="img-fluid phone-img"
                        src="/assets/phone.png"
                      />
                    </div>
                    <p className="text-center py-2 prod-title mb-0">
                      Smart Phone
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center">
                  <div>
                    <div className="phone-circle">
                      <img
                        className="img-fluid laptop-img"
                        src="/assets/laptop.png"
                      />
                    </div>
                    <p className="text-center py-2 prod-title mb-0">
                      Tabs & Laptops
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center">
                  <div>
                    <div className="phone-circle">
                      <img
                        className="img-fluid cam-img"
                        src="/assets/cam.png"
                      />
                    </div>
                    <p className="text-center py-2 prod-title mb-0">
                      Cameras & Lens
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center">
                  <div>
                    <div className="phone-circle">
                      <img
                        className="img-fluid hphone-img"
                        src="/assets/headphone.png"
                      />
                    </div>
                    <p className="text-center py-2 prod-title mb-0">
                      Music system{" "}
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center">
                  <div>
                    <div className="phone-circle">
                      <img className="img-fluid ac-img" src="/assets/ac.png" />
                    </div>
                    <p className="text-center py-2 prod-title mb-0">
                      Air conditioner
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div>
                <h2 className="title">Our Company</h2>
                <p className="para pt-3">
                  Welcome to VNEXT IT Peripheral Solutions Private Limited, your
                  trusted partner in cutting-edge IT peripheral . We are
                  committed to delivering top-notch electrical machinery and
                  apparatus that enhance the functionality and efficiency of
                  your technology ecosystem.
                </p>
                <p className="para">
                  Our core mission is to innovate and provide high-quality IT
                  peripherals that meet the dynamic needs of our clients across
                  various industries. Our product portfolio includes a wide
                  range of devices designed to improve the user experience and
                  performance of your IT infrastructure.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="d-flex justify-content-center align-items-center">
                <img className="img-fluid pro-img" src="/assets/pro.png" />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <div className="pb-3">
            <h2 className="title">Our Guiding Principles</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="d-flex">
                <div className="icon-div focus">
                  <img className="img-fluid" src="/assets/focuscon.png" />
                </div>
                <div className="mx-2 company-con">
                  <h3>Our Mission</h3>
                  <p>
                    Our mission is to drive technological innovation by
                    developing high-quality, reliable IT peripherals that meet
                    the evolving needs of our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="d-flex">
                <div className="icon-div">
                  <img className="img-fluid" src="/assets/eyecon.png" />
                </div>
                <div className="mx-2 company-con">
                  <h3>Our Vision</h3>
                  <p>
                    Our vision is to become a leading provider of reliable and
                    innovative IT peripherals that empower businesses and
                    individuals to achieve their full potential in the digital
                    age.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="d-flex">
                <div className="icon-div">
                  <img className="img-fluid" src="/assets/handicon.png" />
                </div>
                <div className="mx-2 company-con">
                  <h3>Our Leadership</h3>
                  <p>
                    Our company is driven by a team of dedicated professionals
                    their vision and leadership steer VNEXT towards achieving
                    excellence in the IT peripherals market.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-4" style={{ backgroundColor: "#F6F6F6" }}>
        <Container>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="">
                <img
                  src="/assets/Group 615.png"
                  className="img-fluid expertise-img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="py-3">
                <h2 className="title">Our Top Peripherals Brands</h2>
                <p className="para">
                  We pride ourselves on being at the forefront of IT
                  peripherals, providing innovative and reliable solutions to
                  meet the ever-evolving needs of modern businesses. Our
                  expertise spans a wide range of IT products, designed to
                  enhance productivity, streamline operations, and deliver
                  exceptional performance.
                </p>
                <div className="d-flex list-ta">
                  <div>
                    <ul>
                      <li>Deep industry knowledge</li>
                      <li>Comprehensive productrange</li>
                      <li>Unbeatable prices of IT productsfor you</li>
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <li>Every leading brand for you</li>
                      <li>On-time delivery for your organization</li>
                      <li>Proven reliability</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="py-5">
        <Container>
          <div className="row">
            <h2 className="title pb-3">What We Do</h2>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div>
                <div className="d-flex">
                  <div className="me-2 icons-can">
                    <img
                      className="img-fluid"
                      src="/assets/Mask group (46).png"
                    />
                  </div>
                  <div>
                    <div className="d-flex justify-content-start align-items-center">
                      <h3 className="cus-c">Community Supplies</h3>{" "}
                      <i className="fi fi-rr-angle-small-right"></i>
                    </div>
                    <p className="para2">
                      Products and resources intended for use in community or
                      shared environments. These supplies can include a variety
                      of items.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div>
                <div className="d-flex">
                  <div className="me-2 icons-can">
                    <img
                      className="img-fluid"
                      src="/assets/Mask group (47).png"
                    />
                  </div>
                  <div>
                    <div className="d-flex justify-content-start align-items-center">
                      <h3 className="cus-c">Reward Program</h3>{" "}
                      <i className="fi fi-rr-angle-small-right"></i>
                    </div>
                    <p className="para2">
                      Such programs aim to boost sales, encourage loyalty, and
                      strengthen the relationship between the company and its
                      distribution network.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div>
                <div className="d-flex">
                  <div className="me-2 icons-can">
                    <img
                      className="img-fluid"
                      src="/assets/Mask group (48).png"
                    />
                  </div>
                  <div>
                    <div className="d-flex justify-content-start align-items-center">
                      <h3 className="cus-c">Customer Loyalty</h3>{" "}
                      <i className="fi fi-rr-angle-small-right"></i>
                    </div>
                    <p className="para2">
                      Consistent preference and commitment of customers to
                      purchase IT peripherals from the same distributor over
                      time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section>
        <Container>
          <div>
            <img className="img-fluid" src="/assets/Group 648.png" />
          </div>
        </Container>
      </section>

      <section className="py-5" style={{ overflow: "hidden" }}>
        <Container>
          <div className="text-center">
            <h2 className="title">Our Clients</h2>
          </div>
          <div className="py-4">
            <div className="slider-container">
              <Slider {...settings}>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid carousl-img"
                    src="/assets/nac.png"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid carousl-img"
                    src="/assets/cement.png"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid carousl-img"
                    src="/assets/deccan.png"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid carousl-img"
                    src="/assets/csk.png"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid carousl-img"
                    src="/assets/kkr.png"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid carousl-img"
                    src="/assets/cpc.png"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid carousl-img"
                    src="/assets/govt.png"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid carousl-img"
                    src="/assets/odyssey.png"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </Container>
      </section>

      <section className="test-bg">
        <Container>
          <div className="py-3">
            <h2 className="title text-center" style={{ color: "#fff" }}>
              Testimonial{" "}
            </h2>
            <p className="para2 text-center" style={{ color: "#fff" }}>
              Success Stories from Our Valued Clients
            </p>
          </div>
          <div className="set">
            <img className="img-fluid test-img1" src="/assets/test1.png" />
          </div>
          <div className="set">
            <img className="img-fluid test-img2" src="/assets/test2.png" />
          </div>
          <div className="set">
            <img className="img-fluid test-img3" src="/assets/test3.png" />
          </div>
        </Container>
      </section>

      {/* <section className="py-4 mt-4" style={{ backgroundColor: "#F6F6F6" }}>
        <Container>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
              <div className="py-2">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="happy-icon">
                    <img className="img-fluid" src="/assets/dyah.png" />
                  </div>
                  <div className="line mx-2"></div>
                  <div className="happy">
                    <h1 className="mb-0">500</h1>
                    <p className="mb-0">Happy Clients</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
              <div className="py-2">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="happy-icon">
                    <img className="img-fluid" src="/assets/iorhd.png" />
                  </div>
                  <div className="line mx-2"></div>
                  <div className="happy">
                    <h1 className="mb-0">500</h1>
                    <p className="mb-0">Plus Products</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
              <div className="py-2">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="happy-icon">
                    <img
                      className="img-fluid handocn"
                      src="/assets/iudfduj.png"
                    />
                  </div>
                  <div className="line mx-2"></div>
                  <div className="happy">
                    <h1 className="mb-0">500</h1>
                    <p className="mb-0">Successful Years</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section> */}

      <section className="py-5" id="contactus">
        <Container>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.8803109149385!2d80.25717387359038!3d13.043289713305496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267aae917c625%3A0x7a409ab220027eb9!2sVNEXT%20IT%20Peripheral%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1721805755568!5m2!1sen!2sin"
                  width="100%"
                  height="283"
                ></iframe>{" "}
              </div>
              <div className="pt-3">
                <table>
                  <tr>
                    <td className="address-title d-block">Address</td>
                    <td className="para2">: VNEXT IT Peripheral Solutions Private Limited Door
                      No.59, Flat No: 2B, Rajarathinam Apartment, 2nd Floor,
                      T.T.K. Road, Alwarpet, Chennai - 600 018.</td>
                  </tr>
                  <tr>
                    <td className="address-title">Email id</td>
                    <td><a href="mailto:enquiry@vnextit.in" className="para2">
                        : enquiry@vnextit.in
                      </a></td>
                  </tr>
                  <tr>
                    <td className="address-title">Phone no</td>
                    <td><a href="tel:+91 73059 40450" className="para2">
                        : +91 73059 40450
                      </a></td>
                  </tr>
                </table>
              </div>
           
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="contact-sm">
                <h2 className="title">Contact us</h2>
                <form onSubmit={handleContact}>
                  <div>
                    <label className="label">Name *</label>
                    <br />
                    <input
                      className="form-name p-2"
                      value={data.name}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                      required
                    />
                    <br />
                    <div className="d-flex justify-content-between align-items-center py-3">
                      <div style={{ width: "49%" }}>
                        <label className="label">Phone No *</label>
                        <br />
                        <input
                          className="form-phone p-2"
                          value={data.phonenumber}
                          onChange={(e) =>
                            setData({ ...data, phonenumber: e.target.value })
                          }
                          required
                        />
                        <br />
                      </div>
                      <div style={{ width: "49%" }}>
                        <label className="label">Email id *</label>
                        <br />
                        <input
                          className="form-phone p-2"
                          value={data.mail}
                          onChange={(e) =>
                            setData({ ...data, mail: e.target.value })
                          }
                          required
                        />
                        <br />
                      </div>
                    </div>
                    <label className="label">Message *</label>
                    <br />
                    <textarea
                      className="textarea p-2"
                      rows={5}
                      value={data.message}
                      onChange={(e) =>
                        setData({ ...data, message: e.target.value })
                      }
                      required
                    ></textarea>
                    <div className="text-center pt-4">
                      <button className="form-btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default OurProducts;
