import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer/Footer";
import Product from "../Home/Product";
const Products = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  return (
    <>
      <Header />
      <Product />
      <Footer />
    </>
  );
};

export default Products;
