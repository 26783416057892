import React, { useState } from "react";
import { Container } from "react-bootstrap";

const Aboutus = () => {
  const data = [
    {
      img: "/assets/set1.png",
      h2: "Community Supplies",
      para1:
        "Our community supplies initiative is designed to cater to the specific needs of diverse sectors, ensuring that each community receives the tailored support they require. Here’s an overview of how it works",
      h3: "Understanding Community Needs",
      para2:
        "Our first step involves a thorough understanding of the unique requirements of each community we serve. ",
    },
    {
      img: "/assets/set2.png",
      h2: "Community Supplies",
      para1:
        "Our community supplies initiative is designed to cater to the specific needs of diverse sectors, ensuring that each community receives the tailored support they require. Here’s an overview of how it works",
      h3: "Custom Solutions Design",
      para2:
        "Based on the needs assessment, we design custom solutions that align with the community's goals and operational requirements.",
    },
    {
      img: "/assets/set3.png",
      h2: "Community Supplies",
      para1:
        "Our community supplies initiative is designed to cater to the specific needs of diverse sectors, ensuring that each community receives the tailored support they require. Here’s an overview of how it works",
      h3: "Feedback and Improvement",
      para2:
        "We believe in continuous improvement and value feedback from the communities we serve.",
    },
  ];

  const profile = [
    {
      img: "/assets/Mask group (45).png",
      name: "John K",
      role: "CEO of VNEXT",
    },
    {
      img: "/assets/Mask group (45).png",
      name: "John K",
      role: "CEO of VNEXT",
    },
    {
      img: "/assets/Mask group (45).png",
      name: "John K",
      role: "CEO of VNEXT",
    },
    {
      img: "/assets/Mask group (45).png",
      name: "John K",
      role: "CEO of VNEXT",
    },
  ];

  const [isActive, setisActive] = useState(0);

  const handlenum = (num: any) => {
    setisActive(num);
  };
  return (
    <>
      <section className="py-4 margin">
        <Container>
          <div className="text-center hiring py-2">
            <h1>About Us</h1>
            <p>Home - About Us</p>
          </div>
          <div className="row pt-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div>
                <h2 className="title pb-2">Company Overview</h2>
                <p className="welcome-para mb-1">
                  Welcome to VNEXT IT Peripheral Solutions Private Limited
                </p>
                <p className="welcome-para" style={{ textIndent: "40px" }}>
                VNEXT IT Peripheral Solutions Private Limited, it is a forward-thinking 
company based in Chennai, Tamil Nadu. It is involved in the trading and distribution 
of IT peripherals. The company specializes in offering a wide range of computer 
accessories, hardware components, and other technology- related products. 
Our commitment to innovation and excellence drives us to deliver  products that 
enhance the efficiency and performance of technology systems across various 
industries.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div>
                <img className="img-fluid" src="/assets/Group 597.png" />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div>
                <img className="img-fluid" src={data[isActive].img} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="py-2">
                <h3 className="stroke">0{isActive + 1}</h3>
                <h2 className="title">{data[isActive].h2}</h2>
                <p className="para">{data[isActive].para1}</p>
                <h3 className="h3-title">{data[isActive].h3}</h3>
                <p className="para">{data[isActive].para2}</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12  d-flex justify-content-center align-items-center">
              <div>
                <div className="d-flex justify-content-start align-items-center">
                  <div
                    className={`${isActive === 0 ? "stroke-active" : "num"}`}
                    onClick={() => handlenum(0)}
                  >
                    <h3>01</h3>
                  </div>
                  <div>
                    <p className="mb-0">Understanding Community Needs</p>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center py-3">
                  <div
                    className={`${isActive === 1 ? "stroke-active" : "num"}`}
                    onClick={() => handlenum(1)}
                  >
                    <h3>02</h3>
                  </div>
                  <div>
                    <p className="mb-0">Custom Solutions Design</p>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <div
                    className={`${isActive === 2 ? "stroke-active" : "num"}`}
                    onClick={() => handlenum(2)}
                  >
                    <h3>03</h3>
                  </div>
                  <div>
                    <p className="mb-0">Feedback and Improvement</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div>
                <h2 className="title">Reward Program </h2>
                <p className="para">
                  Our way of showing appreciation to our loyal customers. This
                  comprehensive program is designed to reward frequent buyers
                  and encourage long- term relationships. Here’s a detailed
                  breakdown of how the program works:
                </p>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="earn">
                      <img className="img-fluid pb-2" src="/assets/icons8-get-cash.gif"/>
                      <h3>Earning Points</h3>
                      <p>
                        Customers earn points for every dollar spent on IT
                        peripherals, accessories, and services. Higher-value
                        purchases yield more points.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="earn">
                    <img className="img-fluid pb-2" src="/assets/icons8-us-dollar-circled.gif"/>
                      <h3>Redeeming Points</h3>
                      <p>
                        Redeem points for special edition products, extended
                        warranties, or premium support services.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="earn">
                    <img className="img-fluid pb-2" src="/assets/icons8-discount.gif"/>
                      <h3>Special Offers and Discounts</h3>
                      <p>
                        Early or exclusive access to sales during peak seasons
                        like Black Friday, Cyber Monday, and back-to-school
                        periods.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="earn">
                    <img className="img-fluid pb-2" src="/assets/icons8-ticket.gif"/>
                      <h3>Referral Program</h3>
                      <p>
                        Both the referrer and the new customer receive bonus
                        points upon the first purchase made by the referred
                        customer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div>
                <img
                  src="/assets/Group 582.png"
                  className="img-fluid"
                  width={"450px"}
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div>
                <img
                  className="img-fluid"
                  width={"480px"}
                  src="/assets/product-review-shopping-feedbacks-glyph-vector 1.png"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div>
                <h2 className="title">Customer Loyalty</h2>
                <p className="para">
                  We builds and maintains customer loyalty through a
                  multifaceted approach designed to provide exceptional value,
                  personalized experiences, and consistent engagement. Here’s a
                  detailed look at how customer loyalty is cultivated at VNEXT
                </p>
                <div className="ul-tick">
                  <ul>
                    <li>
                      <i className="fi fi-rr-check-circle tick-icon"></i>
                      High-Quality Products and Services
                    </li>
                    <li>
                      <i className="fi fi-rr-check-circle tick-icon"></i>
                      Exceptional Customer Service
                    </li>
                    <li>
                      <i className="fi fi-rr-check-circle tick-icon"></i>
                      Personalization
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      {/* <section className="py-4">
        <Container>
          <h2 className="title text-center pb-4">Our Advisory Groups </h2>
          <div className="row">
            {profile.map((val, i) => (
              <div className="col-lg-3 col-md-3 col-sm-12 d-flex justify-content-center align-items-center">
                <div>
                  <div className="profile">
                    <div>
                      <img
                        src={val.img}
                        width={"149px"}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="text-center pt-3 prof-detail">
                    <h5 className="mb-0">{val.name}</h5>
                    <p>{val.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section> */}
    </>
  );
};

export default Aboutus;
