import React, { useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer/Footer'
import HomeBanner from '../Home/HomeBanner'
import OurProducts from '../Home/OurProducts'

const HomeM = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
    return (
        <>
           <Header/>
           <HomeBanner/>
           <OurProducts/>
           <Footer/> 
        </>
    )
}

export default HomeM
