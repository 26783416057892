import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Routing from "./Router/Routing";

function App() {
  const [isLoader,setIsloader]=useState(false)
  useEffect(()=>{
      setIsloader(true)
      setTimeout(()=>{
        setIsloader(false)
      },3000)
  },[])
  return (
    <>
    {isLoader?
    <section className="gif-sec">
      <div>
        <img src="/assets/VNEXT-GIF.gif" className="img-fluid gif"/>
      </div>
    </section>:
    <Routing/>}
    </>
  );
}

export default App;
