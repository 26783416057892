import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="py-4 footer-sec">
        <Container>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 py-2 pe-4">
              <div>
                <Link to="/">
                  <img
                    className="img-fluid logo"
                    src="/assets/V next Logo-01.png"
                  />
                </Link>
                <p className="f-para pt-2">
                  Empowering Enterprises with Cutting-Edge IT Products SERVING
                  ENTERPRISES FOR <span className="plus">9+</span> YEARS
                </p>
                <h3 className="pb-2 pt-3" style={{fontSize:"16px",fontWeight:700}}>Get in touch</h3>
                <div>
                  <a href="" target="_blank">
                    <img
                      src="/assets/facebook (7) 1.png"
                      className="img-fluid social-media"
                    />
                  </a>
                  <a href="https://www.instagram.com/vnextitperipheralsolutions/" target="_blank" className="mx-4">
                    <img
                      className="img-fluid social-media"
                      src="/assets/instagram (8) 1.png"
                    />
                  </a>
                  <a href="https://x.com/VnextEnquiry" target="_blank">
                    <img
                      className="img-fluid social-media"
                      src="/assets/twitter 1.png"
                    />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 col-sm-12 py-2 our-prod">
              <div className="nav-product">
                <h3>Our Products</h3>
                <ul className="p-0">
                  <li><a href="">Mobile Phones</a></li>
                  <li><a href="">DVD Players</a></li>
                  <li><a href="">Tabs & Laptops</a></li>
                  <li><a href="">Cooking Systems</a></li>
                  <li><a href="">Cameras</a></li>
                  <li><a href="">Refrigerators</a></li>
                  <li><a href="">Music Systems</a></li>
                  <li><a href="">MP3 Players</a></li>
                  <li><a href="">Air Conditioners</a></li>
                  <li><a href="">Home Appliances</a></li>
                  <li><a href="">Washing Machines</a></li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6 col-sm-12 py-2 quick-link">
              <div className="nav-product">
                <h3>Quick Links</h3>
                <ul className="p-0">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/our-products">Our Products</Link>
                  </li>
                  <li>
                    <Link to="/hiring">Hiring</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 py-2">
              <div className="nav-product">
                <h3>Contact Us</h3>
                <div>
                  <div className="d-flex">
                    <div className="loc-img loc me-2">
                      <img className="img-fluid" src="/assets/s-loc.png" />
                    </div>
                    <div>
                      <p className="F-para">
                        VNEXT IT Peripheral Solutions Private Limited Door
                        No.59, Flat No: 2B, Rajarathinam Apartment, 2nd Floor,
                        T.T.K. Road, Alwarpet, Chennai - 600 018.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="loc-img me-2">
                      <img className="img-fluid" src="/assets/s-mail.png" />
                    </div>
                    <div>
                      <p className="F-para">
                        <a href="mailto:enquiry@vnextit.in" className="mail">
                          enquiry@vnextit.in
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="loc-img me-2">
                      <img className="img-fluid" src="/assets/s-phone.png" />
                    </div>
                    <div>
                      <p className="F-para">
                        <a href="tel:+91 73059 40450" className="mail">
                          +91 73059 40450
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <div className="footer-sec copyrights py-3">
        <p className="mb-0">
          Copyrights © 2024 All Rights Reserved by VNEXT IT Peripheral Solutions
          Private Limited
        </p>
      </div>
    </>
  );
};

export default Footer;
